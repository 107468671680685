// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* frontend/components/Header.module.css */

.Header_header__2BkxE {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #ffffff;
    color: #1e1e1e;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.404);
    z-index: 1000;
}

.Header_logo__cQxg9 {
    font-size: 24px;
    font-weight: bold;
    width: 80px;
}

.Header_logoContainer__IH2Vz {
    cursor: pointer;
}

.Header_center__PWpEi {
    font-size: 24px;
        color: #074273;
        margin-bottom: 10px;
        font-weight: bold;
        text-align: center;
}

.Header_userInfo__s4W1F {
    display: flex;
    align-items: center;
    gap: 15px;
}

.Header_logoutButton__BqDCc {
    padding: 8px 16px;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.Header_logoutButton__BqDCc:hover {
    background-color: #d32f2f;
}`, "",{"version":3,"sources":["webpack://./src/components/LIFTrac/HeaderMetrics/Header.module.css"],"names":[],"mappings":"AAAA,0CAA0C;;AAE1C;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,kBAAkB;IAClB,yBAAyB;IACzB,cAAc;IACd,2CAA2C;IAC3C,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;QACX,cAAc;QACd,mBAAmB;QACnB,iBAAiB;QACjB,kBAAkB;AAC1B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,iBAAiB;IACjB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,iCAAiC;AACrC;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["/* frontend/components/Header.module.css */\r\n\r\n.header {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    padding: 10px 20px;\r\n    background-color: #ffffff;\r\n    color: #1e1e1e;\r\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.404);\r\n    z-index: 1000;\r\n}\r\n\r\n.logo {\r\n    font-size: 24px;\r\n    font-weight: bold;\r\n    width: 80px;\r\n}\r\n\r\n.logoContainer {\r\n    cursor: pointer;\r\n}\r\n\r\n.center {\r\n    font-size: 24px;\r\n        color: #074273;\r\n        margin-bottom: 10px;\r\n        font-weight: bold;\r\n        text-align: center;\r\n}\r\n\r\n.userInfo {\r\n    display: flex;\r\n    align-items: center;\r\n    gap: 15px;\r\n}\r\n\r\n.logoutButton {\r\n    padding: 8px 16px;\r\n    background-color: #f44336;\r\n    color: white;\r\n    border: none;\r\n    border-radius: 5px;\r\n    cursor: pointer;\r\n    transition: background-color 0.3s;\r\n}\r\n\r\n.logoutButton:hover {\r\n    background-color: #d32f2f;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `Header_header__2BkxE`,
	"logo": `Header_logo__cQxg9`,
	"logoContainer": `Header_logoContainer__IH2Vz`,
	"center": `Header_center__PWpEi`,
	"userInfo": `Header_userInfo__s4W1F`,
	"logoutButton": `Header_logoutButton__BqDCc`
};
export default ___CSS_LOADER_EXPORT___;
