import React, { useEffect, useState } from 'react';
import styles from './CultosEventos.module.css';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';

const CALENDAR_ID = process.env.REACT_APP_CALENDAR_ID;
const API_KEY = process.env.REACT_APP_API_KEY;

const CultosEventos = () => {
    const [eventos, setEventos] = useState([]);

    useEffect(() => {
        const fetchEventos = async () => {
            try {
                const response = await fetch(`https://www.googleapis.com/calendar/v3/calendars/${CALENDAR_ID}/events?key=${API_KEY}&timeMin=${new Date().toISOString()}&maxResults=10&singleEvents=true&orderBy=startTime`);
                const data = await response.json();
                const publicEvents = data.items.filter(evento => evento.visibility !== 'private');

                const próximosEventos = publicEvents.slice(0, 3);
                setEventos(próximosEventos || []);
            } catch (error) {
                console.error('Erro ao buscar eventos:', error);
                setEventos([]);
            }
        };

        fetchEventos();
    }, []);

    return (
        <section className={styles.cultosEventos}>
            <div className="container">
                <h2>Próximos Cultos e Eventos</h2>
                <ul>
                    {eventos.length > 0 ? (
                        eventos.map((evento) => {
                            const startDate = new Date(evento.start.dateTime || evento.start.date);
                            return (
                                <li key={evento.id} className={styles.eventItem}>
                                    <div className={styles.eventDateContainer}>
                                        <p className={styles.eventDate}>
                                            {format(startDate, 'dd MMM', { locale: pt })}
                                        </p>
                                        <p className={styles.eventTime}>
                                            {format(startDate, 'HH:mm', { locale: pt })}
                                        </p>
                                    </div>
                                    <div className={styles.eventDetails}>
                                        <h3 className={styles.eventTitle}>{evento.summary}</h3>
                                        <p className={styles.eventLocation}>{evento.location || 'Localização não especificada'}</p>
                                    </div>
                                </li>
                            );
                        })
                    ) : (
                        <p>Não há eventos próximos.</p>
                    )}
                </ul>
            </div>
        </section>
    );
};

export default CultosEventos;
