import React, { useEffect } from 'react';

import Menu from '../../../components/Site/Hamburguer/Hamburguer';
import Footer from '../../../components/Site/Footer/Footer';
import CultosEventos from '../../../components/Site/CultosEventos/CultosEventos';

import styles from './Eventos.module.css';

const Eventos = () => {
    useEffect(() => {
        // Rola até o topo da página ao carregar o componente
        window.scrollTo(0, 0);
    }, []); // O array vazio garante que isso aconteça apenas uma vez após o primeiro render

    return (
        <>
            <Menu />
            <main>
                <div className={styles.introTextContainer}>
                    <h2 className={styles.title}>Vê o que está a Acontecer</h2>
                    <h3 className={styles.subtitle}>Explora o nosso calendário</h3>
                    <p className={styles.description}>
                        Vem fazer parte das nossas celebrações semanais, atividades especiais e outros esforços comunitários. Confere o que está a acontecer usando o calendário abaixo.
                    </p>
                </div>

                <div className={styles.calendarContainer}>
                    <iframe
                        src="https://calendar.google.com/calendar/embed?height=600&wkst=2&ctz=Europe%2FLondon&bgcolor=%23ffffff&showTitle=0&showPrint=0&showCalendars=0&showTz=0&hl=pt_PT&src=Z2VyYWxAbGlmdGFkLnB0&color=%23039BE5"
                        width="800"
                        height="600"
                        title="Calendário de Eventos"
                    ></iframe>
                </div>
                <CultosEventos />
            </main>
            <Footer />
        </>
    );
};

export default Eventos;
