// frontend/components/HomeScreen.js
import React from 'react';
import Header from '../../../components/LIFTrac/HeaderMetrics/Header';
import styles from './HomeScreen.module.css';
import { Link } from 'react-router-dom';

const HomeScreen = ({ user, onLogout }) => {
    const { username, role } = user;
    const menuOptions = {
        admin: [
            { label: 'Lista de Crentes', link: '/area-crente/list' },
            { label: 'Eventos | Calendário', link: '/area-crente/calendario' },
            { label: 'Presenças', link: '/area-crente/presenças' },
            { label: 'Louvor', link: '/area-crente/Louvor' },
            { label: 'Definições', link: '/area-crente/definicoes' }
        ],
        pastor: [
            { label: 'Gerir Congregações', link: '/pastor/manage-congregations' },
            { label: 'Configurações', link: '/settings' }
        ],
        lider: [
            { label: 'Gerir Grupo', link: '/lider/manage-group' },
            { label: 'Perfil', link: '/profile' }
        ],
        membro: [
            { label: 'Perfil', link: '/profile' },
            { label: 'Configurações', link: '/settings' }
        ]
    };

    return (
        <div className={styles.homeScreen}>
            <Header username={username} onLogout={onLogout} />

            <div className={styles.content}>
                <div className={styles.card}>
                    <h1 className={styles.welcome}>Bem-vindo, {username}!</h1>
                    <h2 className={styles.role}>Role: {role}</h2>
                    <div className={styles.menu}>
                        {menuOptions[role].map((option, index) => (
                            <Link key={index} to={option.link} className={styles.menuButton}>
                                {option.label}
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeScreen;
