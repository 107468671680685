import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Menu from '../../../components/Site/Hamburguer/Hamburguer';
import Footer from '../../../components/Site/Footer/Footer';
import Banner from '../../../components/Site/Banner/Banner';

import styles from './SobreNos.module.css';

const SobreNos = () => {
    useEffect(() => {
        // Rola até o topo da página ao carregar o componente
        window.scrollTo(0, 0);
    }, []); // O array vazio garante que isso aconteça apenas uma vez após o primeiro render

    const beliefCards = [
        {
            title: "No que Acreditamos",
            description: "Vê como vemos Deus, Jesus, a Bíblia, o homem e muitos aspetos significativos da nossa fé",
            link: "/sobre-nos/declaracao",
            iconSrc: "/assets/Icons/bible-svgrepo-com.svg"
        },
        {
            title: "A nossa História",
            description: "Conhece mais sobre a história da igreja LIFT",
            link: "/sobre-nos/",
            iconSrc: "/assets/Icons/church-svgrepo-com.svg"
        },
        {
            title: "Liderança",
            description: "Conhece os nossos pastores e a nossa liderança",
            link: "/sobre-nos/lideranca",
            iconSrc: "/assets/Icons/people-svgrepo-com.svg"
        }
    ];

    return (
        <div className={styles.pageContainer}>
            <Menu />
            <Banner
                mediaSrc="/assets/sobre-nos-banner.png"
                isVideo={false}
                sloganText="Sobre nós"
                sloganTag="h1"
                subSloganText="Conhece mais sobre a igreja LIFT"
                subSloganTag="h3"
            />
            <main className={styles.mainContent}>
                <section className={styles.beliefsSection}>
                    <div className={styles.beliefsContainer}>
                        {beliefCards.map((card, index) => (
                            <div
                                className={styles.beliefsItem}
                                key={index}
                                style={{
                                    animationDelay: `${index * 0.2}s`
                                }}
                            >
                                <Link to={card.link} className={styles.cardLink}>
                                    <div className={styles.cardIconWrapper}>
                                        <img
                                            src={card.iconSrc}
                                            alt={card.title}
                                            className={styles.cardIcon}
                                        />
                                    </div>
                                    <h3>{card.title}</h3>
                                    <p>{card.description}</p>
                                </Link>
                            </div>
                        ))}
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
};

export default SobreNos;