/* src/pages/Site/Declaracao/Declaracao.js */
import React, { useState, useEffect } from 'react';

import Menu from '../../../../components/Site/Hamburguer/Hamburguer';
import Footer from '../../../../components/Site/Footer/Footer';
import Banner from '../../../../components/Site/Banner/Banner';

import styles from './Declaracao.module.css';

const Declaracao = () => {
    useEffect(() => {
        // Rola até o topo da página ao carregar o componente
        window.scrollTo(0, 0);
    }, []); // O array vazio garante que isso aconteça apenas uma vez após o primeiro render

    const [expandedItems, setExpandedItems] = useState([]);

    const expandAll = () => {
        setExpandedItems(items.map((_, index) => index));
    };

    const collapseAll = () => {
        setExpandedItems([]);
    };

    const toggleItem = (index) => {
        if (expandedItems.includes(index)) {
            setExpandedItems(expandedItems.filter(i => i !== index));
        } else {
            setExpandedItems([...expandedItems, index]);
        }
    };

    // Dados dos itens do acordeão
    const items = [
        {
            icon: 'fas fa-code',
            title: 'Relacionados com a Trindade',
            content: `Cremos na existência de um único Deus eterno, pessoal,
                      eternamente existente em três pessoas distintas: Pai, Filho e
                      Espírito Santo. Cremos em Deus Pai, nunca visto por homem
                      algum, que é Espírito, e, portanto, desprovido de corpo
                      físico, mas que foi revelado em carne por Seu Filho, nosso
                      Senhor Jesus Cristo. Cremos em todos os Seus atributos divinos
                      conforme estão expressos na Bíblia Sagrada. Cremos no Senhor Jesus Cristo, como Filho de Deus, igual ao
                      Pai e ao Espírito em divindade e atributos. Cremos no Seu
                      nascimento virginal, na Sua perfeita humanidade, na Sua vida
                      imaculada, nos Seus milagres, na Sua morte vicária e
                      expiatória, na Sua ressurreição corpórea, na Sua ascensão ao
                      céu, na Sua atual intercessão e mediação. Cremos na Sua
                      segunda vinda em duas fases distintas: a primeira, para
                      arrebatar a Igreja, antes da Grande Tribulação, ressuscitando
                      os crentes fiéis e transformando os crentes vivos,
                      arrebatando-os para o céu. A segunda, para estabelecer o Seu
                      Reino Milenar. Cremos no Espírito Santo. Cremos na sua personalidade,
                      divindade e atividade, que opera a conversão e regeneração do
                      pecador. Igual ao Pai e ao Filho em divindade e atributos.`
        },
        {
            icon: 'fas fa-question',
            title: 'Relacionados com as Sagradas Escrituras',
            content: `Cremos na inspiração verbal da Bíblia, infalível e depositária
                      da verdade e revelação divina. Cremos na sua suprema
                      autoridade como única e suficiente regra de fé e prática.
                      Cremos na sua inerrância e perfeição em tudo o que declara.
                      Cremos na Bíblia como um todo harmónico, com os seus grandes
                      princípios imutáveis. Sentimo-nos obrigados por tudo o que a
                      Bíblia diz, e não por aquilo que ela omite. Seguimos o que
                      está expressamente revelado, e não o subentendido ou
                      acrescentado ao seu texto. Como ministros da Palavra ensinamos
                      toda a Escritura interpretando-a corretamente de acordo com a
                      hermenêutica bíblica.`
        },
        {
            icon: 'fas fa-laugh',
            title: 'Relacionados com o Homem',
            content: `Cremos que o Homem foi criado por Deus à Sua imagem e
                      semelhança para viver em eterna comunhão com Ele. Cremos na
                      queda e na consequente depravação total e universal do ser
                      humano, que o destitui da glória de Deus, e que apenas o
                      arrependimento e a fé na obra redentora de Cristo o poderão
                      restaurar a Deus. Cremos na necessidade do novo nascimento, e
                      regeneração pela fé em Cristo, pela operação do Espírito Santo
                      e da Palavra de Deus. Cremos no perdão dos pecados através de
                      Jesus e Sua expiação; cremos na Salvação eterna e perfeita
                      como uma dádiva da graça divina, na justificação pela fé no
                      sacrifício de Jesus Cristo feito no Calvário. Cremos na
                      santificação instantânea, e na necessidade e possibilidade da
                      santificação progressiva dos crentes, tanto no interior como
                      no exterior, ou seja, no espírito, alma e corpo, e que essa
                      santidade se pauta:
                      a) por uma separação do mundo e do que no mundo há;
                      b) por uma vida simples, isenta de vaidades e extravagâncias;
                      c) por uma semelhança cada vez maior com Cristo e Seus padrões
                      imaculados, o que equivale a uma diferença e distância cada
                      vez maior do mundanismo;
                      d) pela diferença clara e bíblica entre os sexos.`
        },
        {
            icon: 'fas fa-laugh',
            title: 'Relacionados com o Batismo nas Águas',
            content: `Cremos no Batismo nas Águas efetuado por imersão em nome do
                      Pai, do Filho e do Espírito Santo. Este Batismo só deve ser
                      ministrado a pessoas realmente convertidas, nascidas de novo,
                      que evidenciem de forma inequívoca os frutos da nova vida em
                      Cristo. Cremos que o Batismo nas Águas é a porta de entrada
                      para a membrasia da igreja local e para a participação na Ceia
                      do Senhor. Não ministramos o Batismo a pessoas em situação
                      irregular no matrimónio, ou com situações pendentes ou
                      duvidosas que contrariem os princípios bíblicos.`
        },
        {
            icon: 'fas fa-laugh',
            title: 'Relacionados com a Ceia do Senhor',
            content: `Cremos na Ceia do Senhor como um memorial da morte,
                      ressurreição e segunda vinda de Cristo, a qual deve ser
                      ministrada só aos crentes biblicamente batizados e fiéis.`
        },
        {
            icon: 'fas fa-laugh',
            title: 'Relacionados com o Espírito Santo',
            content: `Cremos no importante trabalho do Espírito Santo, na preparação
                      da Igreja e dos crentes individualmente, guiando,
                      fortalecendo, enviando, etc. Cremos no Batismo no Espírito
                      Santo, com a evidência inicial de falar em outras línguas e na
                      prática contínua de falar línguas como forma de edificação
                      pessoal. Cremos na atualidade e necessidade dos dons
                      espirituais distribuídos pelo Espírito Santo à Sua Igreja para
                      o que for útil.`
        },
        {
            icon: 'fas fa-laugh',
            title: 'Relacionados com a Cura Divina',
            content: `Cremos na atualidade da Cura Divina como resultado da obra de
                      Cristo no Calvário, dependente da soberania de Deus. Cremos
                      que nem todas as doenças são procedência de espíritos malignos
                      ou consequência de pecados específicos, embora uma coisa e
                      outra possam também acontecer. Cremos que podemos receber a
                      Cura Divina, em nome de Jesus, como resultado da oração da fé
                      e também através da operação dos dons espirituais, conforme
                      ensina a Bíblia.`
        },
        {
            icon: 'fas fa-laugh',
            title: 'Relacionados com a Ressurreição',
            content: `Cremos na ressurreição dos mortos, tanto dos salvos como dos
                      perdidos, que ocorrerá em duas ocasiões distintas.`
        },
        {
            icon: 'fas fa-laugh',
            title: 'Relacionados com o Juízo Final e o Estado Eterno',
            content: `Cremos no juízo vindouro e na vida eterna de gozo e
                      bem-aventurança dos santos, e na morte eterna como castigo de
                      todos os perdidos. Cremos nos Novos Céus e na Nova Terra como
                      lugar de eterna habitação dos justos.`
        },
        {
            icon: 'fas fa-laugh',
            title: 'Relacionados com a Igreja',
            content: `Cremos que a Igreja de Cristo é constituída por todas as
                      pessoas remidas e nascidas de novo, vivendo em santidade de
                      vida e separados do mundo. Cremos que o governo da igreja
                      local deve ser teocrático no que se refere à doutrina e
                      direção espiritual, e consensual no que se refere à
                      administração, finanças, nomeação e reconhecimento dos seus
                      oficiais. Os crentes deverão ser consultados e informados
                      periodicamente da administração e uso do dinheiro.`
        },
        {
            icon: 'fas fa-laugh',
            title: 'Relacionados com os Anjos e Demónios',
            content: `Cremos na existência de anjos bons que servem a Deus e aos
                      herdeiros da salvação, e de anjos maus: Satanás e os demónios.
                      Cremos que os remidos por Cristo não podem ser possuídos por
                      demónios no seu todo ou em áreas específicas, salvo se
                      deixarem o Senhor e voltarem ao pecado.`
        },
        {
            icon: 'fas fa-laugh',
            title: 'Relacionados com o Dinheiro e Ofertas',
            content: `Cremos que o uso do dinheiro deve seguir o padrão do que é
                      justo e santo, e que todas as ofertas devem ser com alegria e
                      sem constrangimento, seguindo o princípio do dar conforme o
                      coração e não segundo imposição.`
        },
        {
            icon: 'fas fa-laugh',
            title: 'Relacionados com o Casamento e Família',
            content: `Cremos que o matrimónio é uma instituição divina, entre um
                      homem e uma mulher, e que deve ser realizado de acordo com as
                      leis e normas bíblicas. Cremos que a família é o núcleo da
                      sociedade e deve ser baseada nos princípios bíblicos.`
        },
        {
            icon: 'fas fa-laugh',
            title: 'Relacionados com a Lei e a Ordem',
            content: `Cremos na importância de respeitar e seguir as leis do país,
                      desde que não contrariem os princípios bíblicos, e de viver em
                      ordem e respeito pelos direitos e deveres da sociedade.`
        },
    ];

    return (
        <>
            <Menu />
            <Banner
                mediaSrc="/assets/ceia.png"
                isVideo={false}
                sloganText="Declaração de Fé"
                sloganTag="h2"
                subSloganText="LIFTad Igreja Evangélica"
                subSloganTag="h3"
            />
            <div className={styles.contentContainer}>
                <div className={styles.accordionMenu}>
                    <div className={`${styles.accordionMenuButtons} ${styles.topButtons}`}>
                        <button
                            className={styles.accordionMenuButton}
                            onClick={expandAll}
                        >
                            Expandir Tudo
                        </button>
                        <button
                            className={styles.accordionMenuButton}
                            onClick={collapseAll}
                        >
                            Encolher Tudo
                        </button>
                    </div>
                    <ul>
                        {items.map((item, index) => (
                            <li key={index}>
                                <input
                                    type="checkbox"
                                    checked={expandedItems.includes(index)}
                                    readOnly
                                    onClick={() => toggleItem(index)}
                                />
                                <i className={`${styles.arrow} fas fa-chevron-up`}></i>
                                <h2 onClick={() => toggleItem(index)}>
                                    <i className={item.icon}></i>
                                    {item.title}
                                </h2>
                                <p>
                                    {item.content}
                                </p>
                            </li>
                        ))}
                    </ul>
                    <div className={`${styles.accordionMenuButtons} ${styles.bottomButtons}`}>
                        <button
                            className={styles.accordionMenuButton}
                            onClick={collapseAll}
                        >
                            Encolher Tudo
                        </button>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default Declaracao;