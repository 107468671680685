// frontend/components/Header.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Header.module.css';

const Header = ({ username, onLogout }) => {
    const navigate = useNavigate();

    const handleLogoClick = () => {
        if (username) {
            navigate('/area-crente/home');
        } else {
            navigate('/area-crente/login');
        }
    };

    return (
        <header className={styles.header}>
            <div onClick={handleLogoClick} className={styles.logoContainer}>
                <img src="/assets/Logos/logo-igreja.png" alt="Logo da Igreja" className={styles.logo} />
            </div>
            <div className={styles.center}>Área de Crente</div>
            <div className={styles.userInfo}>
                <span>Logado como: {username}</span>
                <button className={styles.logoutButton} onClick={onLogout}>
                    Logout
                </button>
            </div>
        </header>
    );
};

export default Header;
