import React, { useState } from 'react';
import styles from './NovoAqui.module.css';
import emailjs from "@emailjs/browser";

const NovoAqui = ({ onClose }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        content: '',
        consent: false,
    });
    const [step, setStep] = useState(1);
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const validate = () => {
        const newErrors = {};
        if (step === 1 && !formData.name) newErrors.name = "O nome é obrigatório";
        if (step === 2 && !formData.email) newErrors.email = "O email é obrigatório";
        if (step === 4 && !formData.consent) newErrors.consent = "Você deve concordar com o RGPD";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleNext = () => {
        if (validate()) {
            setStep((prevStep) => prevStep + 1);
        }
    };

    const handlePrevious = () => {
        setStep((prevStep) => prevStep - 1);
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.send('service_yzpb2se', 'template_8vj3fmu', formData, 'VVtpX7As6A9k5wp8U')
            .then((response) => {
                alert('Mensagem enviada com sucesso!', response.status, response.text);
                setFormData({
                    name: '',
                    email: '',
                    mobile: '',
                    content: '',
                });
                onClose();
            })
            .catch((err) => {
                alert('Falha ao enviar a mensagem, tente novamente.', err);
            });
    };


    return (
        <div className={styles.overlay}>
            <div className={styles.popup}>
                <button className={styles.closeButton} onClick={onClose}>✕</button>
                <div className={styles.imageSection}>
                    <img src="/assets/novo-aqui_img.png" alt="Conecta-te com a nossa igreja" />
                </div>
                <div className={styles.content}>
                    <h3 className={styles.subtitle}>Conecta-te com a nossa Igreja</h3>
                    <p className={styles.description}>
                        Novo na nossa igreja? Estamos muito felizes por estares aqui. Gostaríamos muito de manter contato e mantê-lo atualizado com tudo o que está a acontecer na LIFTad Igreja Evangélica.
                    </p>
                    <p className={styles.description}>
                        Basta preencheres o formulário abaixo.
                    </p>
                    {step === 1 && (
                        <div className={styles.formGroup}>
                            <label htmlFor="name">Nome *</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                className={errors.name ? styles.errorInput : ''}
                                required
                            />
                            {errors.name && <span className={styles.error}>{errors.name}</span>}
                            <div className={styles.buttonContainer}>
                                <button onClick={handleNext} className={styles.nextButton}>Próximo</button>
                            </div>
                        </div>
                    )}
                    {step === 2 && (
                        <div className={styles.formGroup}>
                            <label htmlFor="email">Email *</label>
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className={errors.email ? styles.errorInput : ''}
                                required
                            />
                            {errors.email && <span className={styles.error}>{errors.email}</span>}

                            <div className={styles.buttonContainer}>
                                <button onClick={handlePrevious} className={styles.prevButton}>Anterior</button>
                                <button onClick={handleNext} className={styles.nextButton}>Próximo</button>
                            </div>
                        </div>
                    )}
                    {step === 3 && (
                        <div className={styles.formGroup}>
                            <label htmlFor="phone">Telefone</label>
                            <input
                                type="tel"
                                id="mobile"
                                name="mobile"
                                value={formData.mobile}
                                onChange={handleChange}
                            />
                            <div className={styles.buttonContainer}>
                                <button onClick={handlePrevious} className={styles.prevButton}>Anterior</button>
                                <button onClick={handleNext} className={styles.nextButton}>Próximo</button>
                            </div>
                        </div>
                    )}
                    {step === 4 && (
                        <div className={styles.formGroup}>
                            <label htmlFor="about">Conta-nos mais um pouco acerca de ti</label>
                            <textarea
                                id="content"
                                name="content"
                                value={formData.content}
                                onChange={handleChange}
                                rows="4"
                            />
                            <div className={styles.formGroupCheckbox}>
                                <input
                                    type="checkbox"
                                    id="consent"
                                    name="consent"
                                    checked={formData.consent}
                                    onChange={handleChange}
                                    required
                                />
                                <label htmlFor="consent">
                                    Concordo que processem os meus dados e assegurem a segurança dos mesmos
                                </label>
                                {errors.consent && <span className={styles.error}>{errors.consent}</span>}
                            </div>
                            <div className={styles.buttonContainer}>
                                <button onClick={handlePrevious} className={styles.prevButton}>Anterior</button>
                                <button onClick={handleSubmit} className={styles.submitButton}>Submeter</button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default NovoAqui;
