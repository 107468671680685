import React, { useEffect } from 'react';

import Menu from '../../../../components/Site/Hamburguer/Hamburguer';
import Footer from '../../../../components/Site/Footer/Footer';
import styles from './Lideranca.module.css';

const Lideranca = () => {
    useEffect(() => {
        // Scroll to the top of the page on component load
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Menu />
            <div className={styles.leadershipSection}>
                <div className={styles.leadershipContent}>
                    <div className={styles.leadershipImage}>
                        <img
                            src="/assets/pastores.jpg"
                            alt="Pastores Humberto e Cristina Carvalheiro"
                            loading="eager"
                        />
                    </div>
                    <div className={styles.leadershipText}>
                        <h2>Conhece a nossa liderança</h2>
                        <div className={styles.leadershipDetails}>
                            <div className={styles.leadershipNames}>
                                <h3>Humberto e Cristina Carvalheiro</h3>
                                <h4>Pastores</h4>
                            </div>
                            <p>
                                Casados há 29 anos pais de dois rapazes o David e o Josias.
                            </p>
                            <p>
                                Gostamos muito de tudo o que é familiar e de estar o máximo de tempo com os nossos amigos, sempre desejámos que a nossa casa fosse um ponto de encontro.
                            </p>
                            <p>
                                Nos gostos somos muito diferentes: o Beto gosta de música de todo o tipo, a Cris é muito seletiva; o Beto gosta de ouvir a música bem alta, a Cris não. Ambos gostamos de animais, mas o Beto prefere cães e a Cris prefere gatos.
                            </p>
                            <p>
                                Normalmente somos divertidos e amigos da boa disposição, mas também temos os nossos dias mais cinzentos; o Beto é o mais rabugento. Temos antecedentes profissionais diferentes: a Cris foi secretária administrativa durante 20 anos o Beto trabalhou em artes gráficas também durante 20 anos.
                            </p>
                            <p>
                                Em 2003 iniciámos o nosso percurso vocacional no Instituto Bíblico das Assembleias de Deus na Foz do Arelho e desde 2005 temos trabalhado na obra de Deus em vários pontos do país e em vários departamentos.
                                Somos os dois loucamente apaixonados pelos ministérios infantis.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Lideranca;
