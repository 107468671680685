import React from 'react';
import PropTypes from 'prop-types';
import styles from './Banner.module.css';

const Banner = ({
    mediaSrc,
    isVideo,
    sloganText,
    sloganTag = 'h1',
    subSloganText,
    subSloganTag = 'h2'
}) => {
    const SloganTag = sloganTag;
    const SubSloganTag = subSloganTag;

    return (
        <div className={styles.banner}>
            <div className={styles.mediaWrapper}>
                {isVideo ? (
                    <video
                        autoPlay
                        loop
                        muted
                        playsInline // Importante para iOS
                        className={styles.media}
                    >
                        <source src={mediaSrc} type="video/mp4" />
                        Seu navegador não suporta a reprodução de vídeo.
                    </video>
                ) : (
                    <img
                        src={mediaSrc}
                        alt="Banner"
                        className={styles.media}
                        loading="eager" // Carrega a imagem com prioridade
                    />
                )}
            </div>
            <div className={styles.sloganContainer}>
                <SloganTag className={styles.slogan}>{sloganText}</SloganTag>
                {subSloganText && (
                    <SubSloganTag className={styles.subSlogan}>
                        {subSloganText}
                    </SubSloganTag>
                )}
            </div>
        </div>
    );
};

Banner.propTypes = {
    mediaSrc: PropTypes.string.isRequired,
    isVideo: PropTypes.bool,
    sloganText: PropTypes.string.isRequired,
    sloganTag: PropTypes.string,
    subSloganText: PropTypes.string,
    subSloganTag: PropTypes.string,
};

Banner.defaultProps = {
    isVideo: false,
    sloganTag: 'h1',
    subSloganTag: 'h2',
};

export default Banner;