// frontend/src/components/LIFTrac/Crentes/CrenteForm.js
import React from 'react';
import styles from './CrenteForm.module.css';

const CrenteForm = ({ newCrente, handleInputChange, handleFormSubmit, closeModal }) => {
    return (
        <div className={styles.modal}>
            <div className={styles.modalContent}>
                <span className={styles.close} onClick={closeModal}>&times;</span>
                <h2>Adicionar Novo Crente</h2>
                <form onSubmit={handleFormSubmit}>
                    {/* Nome obrigatório */}
                    <input
                        type="text"
                        name="nome"
                        placeholder="Nome"
                        value={newCrente.nome}
                        onChange={handleInputChange}
                        required
                    />

                    {/* Data de Nascimento */}
                    <input
                        type="date"
                        name="data_nascimento"
                        placeholder="Data de Nascimento"
                        value={newCrente.data_nascimento}
                        onChange={handleInputChange}
                        required
                    />

                    {/* Morada */}
                    <input
                        type="text"
                        name="morada"
                        placeholder="Morada"
                        value={newCrente.morada}
                        onChange={handleInputChange}
                    />

                    {/* Código Postal */}
                    <input
                        type="text"
                        name="CP"
                        placeholder="Código Postal"
                        value={newCrente.CP}
                        onChange={handleInputChange}
                    />

                    {/* Localidade */}
                    <input
                        type="text"
                        name="localidade"
                        placeholder="Localidade"
                        value={newCrente.localidade}
                        onChange={handleInputChange}
                    />

                    {/* Telemóvel */}
                    <input
                        type="tel"
                        name="tlm"
                        placeholder="Número de Telemóvel"
                        value={newCrente.tlm}
                        onChange={handleInputChange}
                    />

                    {/* Telefone */}
                    <input
                        type="tel"
                        name="tel"
                        placeholder="Número de Telefone"
                        value={newCrente.tel}
                        onChange={handleInputChange}
                    />

                    {/* E-mail */}
                    <input
                        type="email"
                        name="mail"
                        placeholder="E-mail"
                        value={newCrente.mail}
                        onChange={handleInputChange}
                    />

                    {/* Congregação */}
                    <select
                        name="congregacao"
                        value={newCrente.congregacao}
                        onChange={handleInputChange}
                        required
                    >
                        <option value="">Selecione a Congregação</option>
                        <option value="Infantado">Infantado</option>
                        <option value="Fanhoes">Fanhões</option>
                        <option value="Tojal">Tojal</option>
                    </select>

                    {/* Checkbox Batizado */}
                    <label>
                        <input
                            type="checkbox"
                            name="ind_batizado"
                            checked={newCrente.ind_batizado}
                            onChange={handleInputChange}
                        />
                        Batizado
                    </label>
                    {/* Checkbox Carta */}
                    <label>
                        <input
                            type="checkbox"
                            name="ind_carta"
                            checked={newCrente.ind_carta}
                            onChange={handleInputChange}
                        />
                        Carta
                    </label>

                    {/* Checkbox Saída */}
                    <label>
                        <input
                            type="checkbox"
                            name="ind_saida"
                            checked={newCrente.ind_saida}
                            onChange={handleInputChange}
                        />
                        Saída
                    </label>

                    <button type="submit">Salvar</button>
                </form>
            </div>
        </div>
    );
};

export default CrenteForm;
