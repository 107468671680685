import React from 'react';
import styles from './Footer.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.container}>
                <p className={styles.text}>© {new Date().getFullYear()} LIFTad Igreja Evangélica. Todos os direitos reservados.</p>
                <p className={styles.text}>Morada: R. das Lezírias 20, 2670-513 Loures, Infantado (Sede)</p>
                <a href="mailto:geral@liftad.pt">
                    <p className={styles.text}>Email: geral@liftad.pt</p>
                </a>
                <div className={styles.socialLinks}>
                    <a href="https://www.instagram.com/liftad_church/" target="_blank" rel="noopener noreferrer" className={styles.instagramfooter}>
                        <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a href="https://www.facebook.com/adlift.church" target="_blank" rel="noopener noreferrer" className={styles.facebookfooter}>
                        <FontAwesomeIcon icon={faFacebook} />
                    </a>
                    <a href="https://www.youtube.com/@lift8472" target="_blank" rel="noopener noreferrer" className={styles.youtubefooter}>
                        <FontAwesomeIcon icon={faYoutube} />
                    </a>
                    <Link to="/area-crente/login">
                        <FontAwesomeIcon icon={faUser} style={{ color: 'white' }} />
                    </Link>


                </div>
            </div>
        </footer>
    );
};

export default Footer;
