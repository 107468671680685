import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styles from './Login.module.css';

const Login = ({ setUser }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.post('http://localhost:5000/api/auth/login', { username, password });
            localStorage.setItem('token', res.data.token);
            setUser({ username, role: res.data.role, token: res.data.token });
            navigate('/area-crente/home');
        } catch (err) {
            setError('Credenciais inválidas. Por favor, tente novamente.');
        }
    };

    return (
        <div className={styles.pageContainer}>
            <div className={styles.decorativeCircle}></div>
            <div className={styles.loginBox}>
                <div className={styles.logoContainer}>
                    <img src="/assets/Logos/logo-igreja.png" alt="Logo" className={styles.loginLogo} />
                </div>
                <h2 className={styles.title}>Área de Crente</h2>
                <p className={styles.subtitle}>Por favor, faça login para continuar</p>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        placeholder="Nome de Usuário"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        className={styles.loginInput}
                        required
                    />
                    <input
                        type="password"
                        placeholder="Senha"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className={styles.loginInput}
                        required
                    />
                    {error && <p className={styles.error}>{error}</p>}
                    <button type="submit" className={styles.loginButton}>Entrar</button>
                </form>
            </div>
        </div>
    );
};

export default Login;
