// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Forms_caixinhas__g9BcH {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.Forms_nomeCaixa__u3Nvn,
.Forms_emailCaixa__qm5oJ,
.Forms_assuntoCaixa__BP2A2,
.Forms_mensagemCaixa__S09aT {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.Forms_caixaEscreverLado__EMxoD,
.Forms_caixaEscrever__wm3e\\+,
.Forms_caixaEscreverM__pHg3h {
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 16px;
    color: #333;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.404);
}

.Forms_caixaEscreverM__pHg3h {
    height: 180px;
    resize: none;
}

.Forms_submitButton__pNB0F {
    background-color: #007bff;
    color: white;
    padding: 12px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s;
}

.Forms_submitButton__pNB0F:hover {
    background-color: #0056b3;
}`, "",{"version":3,"sources":["webpack://./src/components/Site/Forms/Forms.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;;;;IAII,aAAa;IACb,sBAAsB;IACtB,QAAQ;AACZ;;AAEA;;;IAGI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;IACf,WAAW;IACX,0CAA0C;AAC9C;;AAEA;IACI,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,iCAAiC;AACrC;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".caixinhas {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 15px;\r\n}\r\n\r\n.nomeCaixa,\r\n.emailCaixa,\r\n.assuntoCaixa,\r\n.mensagemCaixa {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 5px;\r\n}\r\n\r\n.caixaEscreverLado,\r\n.caixaEscrever,\r\n.caixaEscreverM {\r\n    padding: 12px;\r\n    border: 1px solid #ddd;\r\n    border-radius: 6px;\r\n    font-size: 16px;\r\n    color: #333;\r\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.404);\r\n}\r\n\r\n.caixaEscreverM {\r\n    height: 180px;\r\n    resize: none;\r\n}\r\n\r\n.submitButton {\r\n    background-color: #007bff;\r\n    color: white;\r\n    padding: 12px;\r\n    border: none;\r\n    border-radius: 6px;\r\n    cursor: pointer;\r\n    font-size: 18px;\r\n    transition: background-color 0.3s;\r\n}\r\n\r\n.submitButton:hover {\r\n    background-color: #0056b3;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"caixinhas": `Forms_caixinhas__g9BcH`,
	"nomeCaixa": `Forms_nomeCaixa__u3Nvn`,
	"emailCaixa": `Forms_emailCaixa__qm5oJ`,
	"assuntoCaixa": `Forms_assuntoCaixa__BP2A2`,
	"mensagemCaixa": `Forms_mensagemCaixa__S09aT`,
	"caixaEscreverLado": `Forms_caixaEscreverLado__EMxoD`,
	"caixaEscrever": `Forms_caixaEscrever__wm3e+`,
	"caixaEscreverM": `Forms_caixaEscreverM__pHg3h`,
	"submitButton": `Forms_submitButton__pNB0F`
};
export default ___CSS_LOADER_EXPORT___;
