// frontend/src/pages/LIFTrac/crentes/Crentes.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from './Crentes.module.css';
import CrenteForm from '../../../components/LIFTrac/Crentes/CrenteForm';
import Header from '../../../components/LIFTrac/HeaderMetrics/Header';
import CrenteDetails from '../../../components/LIFTrac/Crentes/CrenteDetails';

const Crentes = ({ user, onLogout }) => {
    const [crentes, setCrentes] = useState([]);
    const [selectedCrente, setSelectedCrente] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newCrente, setNewCrente] = useState({
        nome: '',
        data_nascimento: '',
        morada: '',
        CP: '', // Código Postal
        localidade: '',
        tlm: '', // Telemóvel
        tel: '', // Telefone
        mail: '', // E-mail
        congregacao: '',
        ind_batizado: false,
        ind_carta: false,
        ind_saida: false,
    });

    const openModal = () => {
        setIsModalOpen(true);
    };

    // função para buscar crentes na base de dados -> apresenta a lista completa e ordenada dos crentes do lado direito da tela
    const fetchCrentes = async () => {
        try {
            const token = localStorage.getItem('token');
            const res = await axios.get('http://localhost:5000/api/crentes', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json; charset=utf-8'
                }
            });
            const sortedCrentes = res.data.sort((a, b) => a.nome.localeCompare(b.nome));
            setCrentes(sortedCrentes);
        } catch (error) {
            console.error('Erro ao buscar crentes:', error);
        }
    };

    useEffect(() => {
        fetchCrentes();
    }, []);

    // função para pesquisar crentes na base de dados -> apresenta a lista de crentes que contém o termo pesquisado
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredCrentes = crentes.filter((crente) =>
        crente.nome.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // função para selecionar um crente da lista de crentes -> apresenta os detalhes do crente selecionado do lado esquerdo da tela
    const handleCrenteClick = (crente) => {
        setSelectedCrente(crente);
    };

    // ------------------------------------ Forms Handle Functions ------------------------------------ //
    // função para lidar com a mudança de valores nos campos do formulário de adição de crente
    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            setNewCrente({
                ...newCrente,
                [name]: checked,
            });
        } else {
            setNewCrente({
                ...newCrente,
                [name]: value,
            });
        }
    };

    // função que lida com a submissão do formulário de adição de crente -> adiciona um novo crente à base de dados
    const handleFormSubmit = async (e) => {
        e.preventDefault();

        // check para congregações válidas, verificando apenas se for preenchida
        const allowedCongregacoes = ['Infantado', 'Fanhoes', 'Tojal'];
        if (newCrente.congregacao && !allowedCongregacoes.includes(newCrente.congregacao)) {
            console.error('Congregação inválida.');
            return;
        }

        const crenteToSubmit = {
            ...newCrente,
            ind_batizado: newCrente.ind_batizado ? 'S' : 'N', // Enviar 'S' para sim, 'N' para não
            ind_carta: newCrente.ind_carta ? 'S' : 'N', // Enviar 'S' para sim, 'N' para não
            ind_saida: newCrente.ind_saida ? 'S' : 'N', // Enviar 'S' para sim, 'N' para não
        };

        // conexão ao backend
        try {
            const token = localStorage.getItem('token');
            await axios.post('http://localhost:5000/api/crentes', crenteToSubmit, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json; charset=utf-8'
                }
            });
            closeModal();
            fetchCrentes();
        } catch (error) {
            console.error('Erro ao adicionar crente:', error);
        }
    };

    // função para fechar o pop-up de adição de crente
    const closeModal = () => {
        setIsModalOpen(false);
        setNewCrente({
            nome: '',
            data_nascimento: '',
            morada: '',
            CP: '',
            localidade: '',
            tlm: '',
            congregacao: '',
            ind_batizado: false,
        });
    };

    return (
        <div>
            <Header username={user.username} onLogout={onLogout} />
            <div className={styles.container}>
                <div className={styles.sidebar}>
                    <h1>Lista de Crentes</h1>
                    <div className={styles.searchBar}>
                        <input
                            type="text"
                            placeholder="Pesquisar crente..."
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                        <button className={styles.addButton} onClick={openModal}>
                            Adicionar
                        </button>
                    </div>
                    <ul className={styles.crentesList}>
                        {filteredCrentes.length > 0 ? (
                            filteredCrentes.map((crente) => (
                                <li
                                    key={crente.id}
                                    onClick={() => handleCrenteClick(crente)}
                                    className={
                                        selectedCrente && selectedCrente.id === crente.id
                                            ? styles.activeCrente
                                            : ''
                                    }
                                >
                                    {crente.nome}
                                </li>
                            ))
                        ) : (
                            <li>Nenhum crente encontrado.</li>
                        )}
                    </ul>
                </div>
                <div className={styles.details}>
                    <CrenteDetails selectedCrente={selectedCrente} />
                </div>
                {isModalOpen && (
                    <CrenteForm
                        newCrente={newCrente}
                        handleInputChange={handleInputChange}
                        handleFormSubmit={handleFormSubmit}
                        closeModal={closeModal}
                    />
                )}
            </div>
        </div>
    );
};

export default Crentes;
