import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import styles from './Forms.module.css';

const Forms = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.send('service_yzpb2se', 'template_h5vv53l', formData, 'VVtpX7As6A9k5wp8U')
            .then((response) => {
                alert('Mensagem enviada com sucesso!', response.status, response.text);
                setFormData({
                    name: '',
                    email: '',
                    subject: '',
                    message: '',
                });
            })
            .catch((err) => {
                alert('Falha ao enviar a mensagem, tente novamente.', err);
            });
    };

    return (
        <form onSubmit={sendEmail} className={styles.caixinhas}>
            <div className={styles.caixasLado}>
                <div className={styles.nomeCaixa}>
                    <p>Nome</p>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className={styles.caixaEscreverLado}
                        required
                    />
                </div>
            </div>
            <div className={styles.emailCaixa}>
                <p>E-mail</p>
                <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className={styles.caixaEscrever}
                    required
                />
            </div>
            <div className={styles.assuntoCaixa}>
                <p>Assunto</p>
                <input
                    type="text"
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    className={styles.caixaEscrever}
                    required
                />
            </div>
            <div className={styles.mensagemCaixa}>
                <p>Mensagem</p>
                <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    className={styles.caixaEscreverM}
                    required
                ></textarea>
            </div>
            <button type="submit" className={styles.submitButton}>
                Enviar Mensagem
            </button>
        </form>
    );
};

export default Forms;
