import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import styles from './calendario.module.css';
import Header from '../../../components/LIFTrac/HeaderMetrics/Header';

const CALENDAR_ID = process.env.REACT_APP_CALENDAR_ID;
const API_KEY = process.env.REACT_APP_API_KEY;

const Calendario = ({ user, onLogout }) => {
    const [eventos, setEventos] = useState([]);

    useEffect(() => {
        const fetchEventos = async () => {
            try {
                const response = await fetch(`https://www.googleapis.com/calendar/v3/calendars/${CALENDAR_ID}/events?key=${API_KEY}&timeMin=${new Date().toISOString()}&maxResults=10&singleEvents=true&orderBy=startTime`);
                const data = await response.json();

                const próximosEventos = data.items.slice(0, 4); // Ensure data.items is used
                setEventos(próximosEventos || []);
            } catch (error) {
                console.error('Erro ao buscar eventos:', error);
                setEventos([]);
            }
        };

        fetchEventos();
    }, []);

    return (
        <div className={styles.calendarioPage}>
            <Header username={user.username} onLogout={onLogout} />
            <div className={styles.calendarContainer}>
                <iframe
                    className={styles.calendarIframe}
                    src="https://calendar.google.com/calendar/embed?height=600&wkst=2&ctz=Europe%2FLondon&bgcolor=%23ffffff&showTitle=0&showPrint=0&showCalendars=0&showTz=0&hl=pt_PT&src=Z2VyYWxAbGlmdGFkLnB0&color=%23039BE5"
                    width="800"
                    height="600"
                    title="Calendário de Eventos"
                ></iframe>
                <section className={styles.cultosEventos}>
                    <div className={styles.container}>
                        <h2>Próximos Cultos e Eventos</h2>
                        <ul>
                            {eventos.length > 0 ? (
                                eventos.map((evento) => {
                                    const startDate = new Date(evento.start.dateTime || evento.start.date);
                                    return (
                                        <li key={evento.id} className={styles.eventItem}>
                                            <div className={styles.eventDateContainer}>
                                                <p className={styles.eventDate}>
                                                    {format(startDate, 'dd MMM', { locale: pt })}
                                                </p>
                                                <p className={styles.eventTime}>
                                                    {format(startDate, 'HH:mm', { locale: pt })}
                                                </p>
                                            </div>
                                            <div className={styles.eventDetails}>
                                                <h3 className={styles.eventTitle}>{evento.summary}</h3>
                                                <p className={styles.eventLocation}>{evento.location || 'Localização não especificada'}</p>
                                            </div>
                                        </li>
                                    );
                                })
                            ) : (
                                <p>Não há eventos próximos.</p>
                            )}
                        </ul>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default Calendario;
