import React, { useState, useEffect } from 'react';
import styles from './Ministerios.module.css';

import Menu from '../../../components/Site/Hamburguer/Hamburguer';
import Footer from '../../../components/Site/Footer/Footer';

const Ministerios = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const ministerios = [
        {
            nome: 'LIFT4KIDS',
            subtitulo: 'Ministério Infantil',
            descricao: 'A brincar, mas sempre a aprender a conhecer Jesus\n\nInfantado: Domingos - 11h30 (1º Domingo 9h30)\n\nFanhões: Domingos - 9h30',
            imagem: '/assets/Logos/lift4kids.png',
            cor: styles.bgBlue,
        },
        {
            nome: 'LIFTeens',
            subtitulo: 'Ministério de Adolescentes (13-16)',
            descricao: 'Infantado: Reuniões 2º e 4º Sábados do mês - 16h',
            imagem: '/assets/Logos/lifteens.png',
            cor: styles.bgPurple,
        },
        {
            nome: '100 Limites',
            subtitulo: 'Ministério de Jovens (17-30)',
            descricao: 'Infantado: Reuniões 2º Sábado do mês- 18h',
            imagem: '/assets/Logos/jovens.png',
            cor: styles.bgRed,
        },
        {
            nome: 'Yanis',
            subtitulo: 'Ministério para Mulheres',
            descricao: 'Yanis no hebraico significa "presente de Deus"',
            imagem: '/assets/Logos/yanis.png',
            cor: styles.bgPink,
        },
        {
            nome: 'ActionMan',
            subtitulo: 'Ministério para homens',
            descricao: 'Não desperdiçamos tempo a discutir como o homem bom deverá ser. Empenhamo-nos em sê-lo.\n\nReuniões na 3ª Segunda do mês às 21h.',
            imagem: '/assets/Logos/homens.png',
            cor: styles.bgGreen,
        },
        {
            nome: '60+',
            subtitulo: 'Ministério da terceira idade',
            descricao: 'A experiência em alegre existência',
            imagem: '/assets/Logos/60+.png',
            cor: styles.bgOrange,
        },
        {
            nome: '+Juntos',
            subtitulo: 'Ministérios para casais',
            descricao: 'Um homem sozinho pode ser vencido, mas dois conseguem defender-se. Um cordão de três dobras não se rompe com facilidade.',
            imagem: '/assets/Logos/casais.png',
            cor: styles.bgYellow,
        },
        {
            nome: 'LIFTart',
            subtitulo: 'Escola de Música',
            descricao: 'A arte diz o indizível; exprime o inexprimível, traduz o intraduzível.',
            imagem: '/assets/Logos/liftart.png',
            cor: styles.bgIndigo,
        },
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => {
            if (prevIndex + 3 >= ministerios.length) {
                return 0;
            }
            return prevIndex + 3;
        });
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => {
            if (prevIndex - 3 < 0) {
                return Math.floor((ministerios.length - 1) / 3) * 3;
            }
            return prevIndex - 3;
        });
    };

    return (
        <>
            <Menu />
            <div className={styles.ministeriosPage}>
                <div className={styles.heroSection}>
                    <div className={styles.heroImageContainer}>
                        <img src="/assets/ministerios_img.png" alt="Imagem Hero" className={styles.heroImage} />
                    </div>
                    <div className={styles.heroContentContainer}>
                        <div className={styles.heroContent}>
                            <h1 className={styles.heroTitle}>Os Nossos Ministérios</h1>
                            <p className={styles.heroSubtitle}>
                                Acreditamos que cada um tem algo único para oferecer
                            </p>
                            <p className={styles.heroSubtitle}>
                                Oferecemos uma variedade de ministérios e programas concebidos para te ajudar a crescer na tua fé e a explorar novas possibilidades
                            </p>
                            <p className={styles.heroSubtitle}>
                                Descobre as diferentes formas de servir e crescer na nossa igreja
                            </p>
                        </div>
                        <div className={styles.carouselContainer}>
                            <div className={styles.carouselControls}>
                                <button
                                    className={`${styles.arrowButton} ${styles.leftArrow}`}
                                    onClick={prevSlide}
                                >
                                    &lt;
                                </button>
                                <button
                                    className={`${styles.arrowButton} ${styles.rightArrow}`}
                                    onClick={nextSlide}
                                >
                                    &gt;
                                </button>
                            </div>
                            <div className={styles.carousel}>
                                {ministerios.slice(currentIndex, currentIndex + 3).map((ministerio, index) => (
                                    <div key={index} className={`${styles.card} ${ministerio.cor}`}>
                                        <div className={styles.cardTopBorder} />
                                        <div className={styles.cardContent}>
                                            <div className={styles.iconContainer}>
                                                <div className={styles.icon}>
                                                    <img
                                                        src={ministerio.imagem}
                                                        alt={ministerio.nome}
                                                        className={styles.iconImage}
                                                    />
                                                </div>
                                            </div>
                                            <div className={styles.cardTextContent}>
                                                <h2 className={styles.cardTitle}>{ministerio.nome}</h2>
                                                <h3 className={styles.cardSubtitle}>{ministerio.subtitulo}</h3>
                                                <div className={styles.description}>
                                                    {ministerio.descricao.split('\n\n').map((paragrafo, i) => (
                                                        <p key={i} className={styles.descriptionText}>
                                                            {paragrafo}
                                                        </p>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Ministerios;