import React from 'react';
import styles from './PlanearVisita.module.css';
import { useNavigate } from 'react-router-dom';

const PlanearVisita = ({ onClose }) => {
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        onClose();
        navigate(path);
    };

    return (
        <div className={styles.overlay}>
            <div className={styles.popup}>
                <button className={styles.closeButton} onClick={onClose}>✕</button>
                <div className={styles.imageSection}>
                    <img src="/assets/novo-aqui_img.png" alt="Conecta-te com a nossa igreja" />
                </div>
                <div className={styles.content}>
                    <h3 className={styles.subtitle}>Planeia a tua visita</h3>
                    <p className={styles.description}>
                        Olá! 👋 Estamos ansiosos por te conhecer e ajudar-te a integrar na nossa família.
                    </p>
                    <p className={styles.description}>
                        Na LIFT, estamos comprometidos com a nossa missão de ajudar os outros a encontrar e seguir Jesus.
                        Visita-nos numa das nossas celebrações de domingo e conhece-nos melhor:
                    </p>
                    <p className={styles.horario}>
                        <strong>Infantado:</strong>Domingos às 9h30 e 16h (2º e 4º domingos do mês)<br />
                        <strong>Fanhões:</strong> Domingos às 9h30<br />
                        <strong>Tojal:</strong> Domingos às 16h<br />
                    </p>
                    <p className={styles.description}>
                        Se não podes estar connosco ao domingo, não há problema ainda te podes juntar a nós!
                        És bem-vindo a participar noutra atividade regular da nossa igreja.
                    </p>
                    <p className={styles.description}>
                        Entre em contacto conosco para agendar uma visita ou para mais informações.
                    </p>
                    <div className={styles.navigationButtons}>
                        <button
                            className={styles.navigationButton}
                            onClick={() => handleNavigation('/eventos')}
                        >
                            Ver Calendário
                        </button>
                        <button
                            className={styles.navigationButton}
                            onClick={() => handleNavigation('/contactos')}
                        >
                            Contactos
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PlanearVisita;