/* src/pages/Site/Historia/Historia.js */
import React, { useEffect } from 'react';

import Menu from '../../../../components/Site/Hamburguer/Hamburguer';
import Footer from '../../../../components/Site/Footer/Footer';
import Banner from '../../../../components/Site/Banner/Banner';

import styles from './Historia.module.css';

const Historia = () => {
    useEffect(() => {
        // Rola até o topo da página ao carregar o componente
        window.scrollTo(0, 0);
    }, []); // O array vazio garante que isso aconteça apenas uma vez após o primeiro render

    return (
        <>
            <Menu />
            <Banner
                mediaSrc="/assets/story-banner.png"
                isVideo={false}
                sloganText="A nossa história"
                sloganTag="h1"
            />
            <main className={styles.mainContent}>
                <section className={styles.introduction}>
                    <h2>Bem-vindo à nossa história</h2>
                    <p>
                        A LIFT é uma igreja evangélica filiada na Convenção das Assembleias de Deus em Portugal. <br /><br />

                        É uma igreja multiétnica, multietária e com caráter acolhedor que se reúne regularmente para o desenvolvimento da comunhão revelando <br />
                        assim o amor por Jesus Cristo e pela Sua palavra, é uma igreja empenhada em conhecer DEUS e conhecer a VIDA.
                    </p>
                </section>
                <section className={styles.timeline}>
                    <div className={styles.timelineEvent}>
                        <div className={styles.timelineDate}>1990</div>
                        <div className={styles.timelineContent}>
                            <h3>Fundação da Empresa</h3>
                            <p>
                                Nosso início foi marcado pela paixão em criar soluções inovadoras. Com uma pequena equipe, começamos a
                                desenvolver produtos que hoje são referência no mercado. Esse foi apenas o começo de uma jornada de
                                sucesso e crescimento contínuo.
                            </p>
                            <div className={styles.timelineImages}>
                                <img src='/assets/Logos/jovens' alt="Fundação" className={styles.timelineImage} />
                                <img src='/assets/Logos/jovens' alt="Expansão" className={styles.timelineImage} />
                            </div>
                        </div>
                    </div>
                    <div className={styles.timelineEvent}>
                        <div className={styles.timelineDate}>2000</div>
                        <div className={styles.timelineContent}>
                            <h3>Expansão Internacional</h3>
                            <p>
                                Expandimos nossas operações para o mercado internacional, oferecendo nossos produtos para um público global.
                            </p>
                            <div className={styles.timelineImages}>
                                <img src='/assets/Logos/jovens' alt="Lançamento" className={styles.timelineImage} />
                                <img src='/assets/Logos/jovens' alt="Transformação Digital" className={styles.timelineImage} />
                            </div>
                        </div>
                    </div>
                    {/* Adicione mais eventos conforme necessário */}
                </section>
            </main>
            <Footer />
        </>
    );
};

export default Historia;
