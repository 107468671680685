// frontend/src/components/LIFTrac/Crentes/CrenteDetails.js
import React, { useState } from 'react';
import styles from './CrenteDetails.module.css';

// Função auxiliar para formatar datas
const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};

// Função auxiliar para calcular a idade a partir de uma data de nascimento
const calculateAge = (birthDate) => {
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const monthDiff = today.getMonth() - birth.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
        age--;
    }
    return age;
};

const CrenteDetails = ({ selectedCrente }) => {
    const [activeTab, setActiveTab] = useState('details');

    if (!selectedCrente) {
        return <h2 className={styles.noSelection}>Selecione um crente para ver os detalhes</h2>;
    }

    const {
        nome,
        data_nascimento,
        tlm,
        mail,
        morada,
        CP,
        localidade,
        congregacao,
        ind_batizado,
        status,
        data_saida
    } = selectedCrente;

    const formattedDate = formatDate(data_nascimento);
    const age = calculateAge(data_nascimento);
    const fullAddress = `${morada}, ${CP} ${localidade}`;

    console.log(ind_batizado);

    return (
        <div className={styles.detailsContainer}>
            <div className={styles.header}>
                <h1>{nome}</h1>
                <button className={styles.editButton}>Editar</button>
            </div>

            <div className={styles.tabContainer}>
                <button
                    className={`${styles.tab} ${activeTab === 'details' ? styles.activeTab : ''}`}
                    onClick={() => setActiveTab('details')}
                >
                    Detalhes
                </button>
            </div>

            <div className={styles.content}>
                <ul className={styles.detailsList}>
                    <li><strong>Data de Nascimento:</strong> {formattedDate}</li>
                    <li><strong>Idade:</strong> {age} anos</li>
                    <li><strong>Telemóvel:</strong> {tlm}</li>
                    <li><strong>Email:</strong> {mail}</li>
                    <li><strong>Morada:</strong> {fullAddress}</li>
                    <li><strong>Congregação:</strong> {congregacao}</li>
                    <li><strong>Batizado:</strong> {ind_batizado === 'S' ? 'Sim' : 'Não'}</li>
                    <li><strong>Status:</strong> {status}</li>
                    <li><strong>Data de Saída:</strong> {data_saida ? formatDate(data_saida) : 'N/A'}</li>
                </ul>
            </div>
        </div>
    );
};

export default CrenteDetails;
