import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './HomePage.module.css';
import Menu from '../../../components/Site/Header_HomePage/header_homepage';
import Footer from '../../../components/Site/Footer/Footer';
import NovoAqui from '../NovoAqui/NovoAqui';
import PlaneiaVisita from '../PlanearVisita/PlanearVisita';

const HomePage = () => {
    useEffect(() => {
        // Rola até o topo da página ao carregar o componente
        window.scrollTo(0, 0);
    }, []); // O array vazio garante que isso aconteça apenas uma vez após o primeiro render

    const [isPopupOpenNovoAqui, setIsNovoAquiPopupOpen] = useState(false);
    const [isPlaneiaVisitaOpen, setIsPlaneiaVisitaOpen] = useState(false);

    // Funções para abrir e fechar o pop-up
    const openPopupNovoAqui = () => setIsNovoAquiPopupOpen(true);
    const closePopupNovoAqui = () => setIsNovoAquiPopupOpen(false);
    const openPlaneiaVisita = () => setIsPlaneiaVisitaOpen(true);
    const closePlaneiaVisita = () => setIsPlaneiaVisitaOpen(false);

    const [isMenuVisible, setIsMenuVisible] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);
    const scrollLimit = 200; // Ponto onde o menu deve reaparecer

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;

            // Condição para ocultar o menu ao rolar para baixo e mostrar ao retornar próximo do topo
            if (currentScrollY > lastScrollY && currentScrollY > scrollLimit) {
                setIsMenuVisible(false); // Oculta o menu ao descer
            } else if (currentScrollY < scrollLimit) {
                setIsMenuVisible(true); // Mostra o menu ao retornar ao topo
            }

            setLastScrollY(currentScrollY);
        };

        window.addEventListener('scroll', handleScroll);

        // Remove o event listener ao desmontar o componente
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY]);

    return (
        <>
            {/* Aplica classe condicional para o menu visível ou oculto */}
            <div className={`${styles.header} ${isMenuVisible ? styles.menuVisible : styles.menuHidden}`}>
                <Menu />
            </div>

            <div className={styles.container}>
                {/* Hero Section with Video Background */}
                <section className={styles.hero}>
                    <img src="/assets/homescreen_img1.png" alt="homescreen_banner" className={styles.videoBackground} />
                    <div className={styles.gradientOverlay}></div>
                    <div className={styles.heroContent}>
                        <div className={styles.leftColumn}>
                            <h1>Conhecer a DEUS Conhecer a VIDA</h1>
                        </div>
                        <div className={styles.rightColumn}>
                            <p className={styles.subTitle}>Bem vindo/a à LIFT igreja evangélica</p>
                            <button className={styles.ctaButton} onClick={openPopupNovoAqui}>Novo aqui?</button>
                            <button className={styles.ctaButton}><Link to="/ministerios" style={{ color: 'inherit', textDecoration: 'none' }}>Junta-te a nós</Link></button>
                            <button className={styles.ctaButton}><Link to="/sobre-nos" style={{ color: 'inherit', textDecoration: 'none' }}>Conhecer a igreja</Link></button>
                        </div>
                    </div>
                </section>

                {/* About Us Section */}
                <section className={styles.aboutUs}>
                    <div className={styles.aboutUsContent}>
                        <h2>Conhecer a DEUS, Conhecer a VIDA</h2>
                        <p>
                            A igreja <strong>LIFT</strong> é uma comunidade movida pela curiosidade compassivamente saudável.
                            Acreditamos que o conhecimento de quem somos e o que nos liga como seres humanos nos transforma.
                            Buscamos <strong>CONHECER A VIDA</strong> através das nossas origens, experiências e relações, e isso nos leva a expressar compaixão, tanto nas alegrias quanto nas dificuldades.
                        </p>

                        <p2>
                            Acima de tudo, a nossa jornada é uma busca profunda por <strong>CONHECER DEUS</strong>, a fonte da nossa humanidade.
                            Ao conhecê-Lo, somos capacitados a viver uma vida abundante, cheia de propósito e realização.
                        </p2>

                        <p_end>
                            Vem connosco,<br />
                            <strong>Conhecer a Deus, Conhecer a Vida.</strong>
                        </p_end>
                        <button className={styles.aboutUsButton}><Link to="/sobre-nos" style={{ color: 'inherit', textDecoration: 'none' }}>Sobre Nós</Link></button>
                    </div>
                </section>

                {/* Plan Your Visit Section */}
                <section className={styles.planVisit}>
                    <div className={styles.planVisitImage}>
                        <img
                            src="/assets/homescreen_img2.png"
                            alt="Plan your visit"
                        />
                    </div>
                    <div className={styles.planVisitContent}>
                        <div className={styles.contentWrapper}>
                            <span className={styles.serviceTime}>09:30 aos Domingos</span>
                            <h2>Planeia a tua visita</h2>
                            <p>
                                Junta-te a nós presencialmente! Adoraríamos receber-te.
                                Encontra os horários dos cultos, direções e tudo o que precisas
                                de saber para nos visitares pela primeira vez clicando no botão abaixo.
                            </p>
                            <button className={styles.PlanVisitButton} onClick={openPlaneiaVisita}>Planear Visita</button>
                        </div>
                    </div>
                </section>

                {/* Staff and Calendar Section */}
                <section className={styles.staffCalendar}>
                    <div className={styles.staffSection}>
                        <div className={styles.imageContainer}>
                            <img
                                src="/assets/homescreen_img3.png"
                                alt="Church staff"
                            />
                        </div>
                        <div className={styles.contentContainer}>
                            <span className={styles.sectionLabel}>Conhece a nossa Equipa</span>
                            <h2>Os Nossos Pastores</h2>
                            <p>
                                A nossa equipa dedicada de pastores e líderes está aqui para fornecer orientação e apoio espiritual - bem como recursos para te ajudar na tua jornada de fé
                            </p>
                            <p></p>
                            <button className={styles.ctaButton}><Link to="/sobre-nos/lideranca" style={{ color: 'inherit', textDecoration: 'none' }}>Liderança</Link></button>
                        </div>
                    </div>

                    <div className={styles.calendarSection}>
                        <div className={styles.imageContainer}>
                            <img
                                src="/assets/homescreen_img4.png"
                                alt="Church events"
                            />
                        </div>
                        <div className={styles.contentContainer}>
                            <span className={styles.sectionLabel}>A nossa agenda</span>
                            <h2>Vê o que está a acontecer</h2>
                            <p>
                                Consulta as atividades deste mês para descobrir o que está a acontecer e como podes participar.
                                Explore a lista completa das próximas atividades e celebrações.
                            </p>
                            <div className={styles.eventTimes}>
                                <p><strong>Domingos 09:30 e 16:00</strong> - Celebrações</p>
                                <p><strong>Quarta 21:00</strong> - Conhecer a Palavra - Estudo Bíblico</p>
                            </div>
                            <button className={styles.ctaButton}><Link to="/eventos" style={{ color: 'inherit', textDecoration: 'none' }}>Calendário</Link></button>
                        </div>
                    </div>
                </section>

                {/* Next Steps Section */}
                <section className={styles.nextSteps}>
                    <div className={styles.planVisitContent}>
                        <div className={styles.contentWrapper}>
                            <span className={styles.serviceTime}>Junta-te a nós</span>
                            <h2>Próximos Passos</h2>
                            <p>
                                Da nossa família para a tua. Descobre mais como te podes envolver na nossa igreja.
                                Explore a vida da nossa igreja, incluindo os nossos ministérios vibrantes, eventos futuros e oportunidades de serviço
                            </p>
                            <button className={styles.PlanVisitButton}><Link to="/ministerios" style={{ color: 'inherit', textDecoration: 'none' }}>Participa</Link></button>
                        </div>
                    </div>
                    <div className={styles.planVisitImage}>
                        <img
                            src="/assets/homescreen_img5.png"
                            alt="Plan your visit"
                        />
                    </div>
                </section>
                {isPopupOpenNovoAqui && <NovoAqui onClose={closePopupNovoAqui} />}
                {isPlaneiaVisitaOpen && <PlaneiaVisita onClose={closePlaneiaVisita} />}
            </div>
            <Footer />
        </>
    );
};

export default HomePage;


